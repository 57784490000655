.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px 0;
}

.App-side {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px 0;
}

.App-link {
  color: #8261fb;
  padding: 10px 0;
  font-size: calc(0.1px + 1vmin);    
}

.App-link-main {
  color: #fb6180;  
  padding: 100px 0;   
}
